import { render, staticRenderFns } from "./questionnaireItem.vue?vue&type=template&id=571d5556&scoped=true"
import script from "./questionnaireItem.vue?vue&type=script&lang=js"
export * from "./questionnaireItem.vue?vue&type=script&lang=js"
import style0 from "./questionnaireItem.vue?vue&type=style&index=0&id=571d5556&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "571d5556",
  null
  
)

export default component.exports
<template>
  <div class="questionnaireItem" @click="involved">
   <!-- 头部背景 -->
    <div class="cover">
      <van-image v-RandomBgColor class="waterfall-img" :src="itemData.picUrl" alt="" />
      <div class="userInfo">
        <div class="user">
          <img :src="itemData.favator">{{itemData.fnickName}}
        </div>
        <div v-if="itemData.type===0" class="userInfo-r">
          <img class="icon" src="@/assets/img/home/comment.png"><span>{{itemData.evaluation}}</span>
        </div>
      </div>
    </div>
    <!-- 状态 -->
    <div class="state">
      <p>{{itemData.status==0?'未开始':itemData.status==1 ?'进行中':'已结束'}}</p>
    </div>
    <!-- 中间内容 -->
    <div class="content">
      <h3 :class="{name:true}">
        <img v-if="itemData.type===0 || itemData.type===2" class="icon" src="@/assets/img/home/wenda.png" alt="">
        <img v-if="itemData.type===1" class="icon" src="@/assets/img/home/wenjuan.png" alt="">
        {{itemData.name}}
      </h3>
      <p class="dec">{{itemData.dec}}</p>
      <!-- 价格区域 -->
      <div class="action">
        <p class="money">¥{{itemData.money}}</p>
        <van-button round type="info" color="#3E639A" @click="involved">我要参与</van-button>
      </div>
    </div>
  </div>
</template>

<script>
  import like from '@/components/like'
  import {Image, Toast} from "vant";
  import Vue from "vue";
  Vue.use(Image).use(Toast);

  export default {
    name: "questionnaireItem",
    components: {
      like
    },
    props: {
      itemData: Object,
      index: Number,
      isFinish: Boolean
    },
    data() {
      return {};
    },
    created() {},
    methods: {
      involved(){
        if (this.itemData.status !== 1) {
          this.$toast('活动暂时关闭，请等待下次开启')
          return;
        }

        if (this.itemData.type == 1){
          if (this.isFinish){
            Toast("问卷活动已参与，无需重复作答")
          }else if (this.itemData.accessLinks){
            window.open(this.itemData.accessLinks)
          }else {
            Toast("问卷链接出错，请稍后再试")
          }
        }else {
          this.$router.push({path: '/activity/details?id='+this.itemData.id},
            onComplete => {}, onAbort => {})
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  .questionnaireItem {
    width: 335px;
    background-color: #FFFFFF;
    border-radius: 20px;
    position: relative;

    .cover {
      .waterfall-img{
        display: block;
        border-radius:  20px 20px 0 0;
        overflow: hidden;
        width: 100%;
        height: auto;
        min-height: 100px;
        max-height: 360px;
      }

      //img {
      //  display: block;
      //  width: 100%;
      //  height: auto;
      //}

      position: relative;
    }

    .state {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 76px;
      height: 32px;
      background: rgba(0, 0, 0, 0.47);
      box-shadow: 0px 0px 4px 0px rgba(74, 73, 73, 0.39);
      border-radius: 16px;
      font-size: 20px;
      color: #FFFFFF;
      text-align: center;
      line-height: 32px;

      p {
        transform: scale(0.83);
      }
    }

    .content {
      padding: 16px 12px 16px 20px;
      box-sizing: border-box;

      .name {
        font-size: 28px;
        color: #4B4B4B;
        line-height: 40px;
        margin-bottom: 8px;

        .icon {
          width: 56px;
          height: 32px;
          vertical-align: middle;
        }
      }

      .dec {
        font-size: 22px;
        font-weight: 500;
        color: #999999;
        line-height: 32px;
      }
    }

    .userInfo {
      width: 335px;
      display: flex;
      justify-content: space-between;
      position: absolute;
      left: 0;
      padding-left: 16px;
      padding-right: 12px;
      bottom: 12px;

      .user {
        display: flex;
        font-size: 20px;
        color: #FFFFFF;

        img {
          width: 34px;
          height: 34px;
          background: #D8D8D8;
          border: 2px solid #FFFFFF;
          border-radius: 50%;
          margin-right: 6px;
        }
      }

      .userInfo-r {
        display: flex;
        align-items: center;
        font-size: 20px;
        color: #EEEEEE;
        line-height: 28px;

        span {
          transform: scale(0.83);
        }

        .icon {
          margin-right: 6px;
          width: 20px;
          height: 18px;

          & /deep/ img {
            display: flex;
            align-items: center;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    .action{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .money{
        font-size: 26px;
        color: #D93E34;
        line-height: 36px;
      }
      button{
        width: 132px;
        padding: 0;
        font-size: 22px;
        height: 48px;
        background: #3E639A;
        border-radius: 24px;
      }
    }
  }
</style>
